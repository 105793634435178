<div class="profile">

<form [formGroup]="profileFormGroup" style="width: 80%;">
    <div>
        <mat-form-field appearance="standard" required>
            <mat-label>First name</mat-label>
            <input matInput placeholder="Title of the view" [formControl]="profileFormGroup.get('name')">
          </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="standard" required>
            <mat-label>Last name</mat-label>
            <input matInput placeholder="Title of the view" [formControl]="profileFormGroup.get('lastname')">
          </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="standard" required>
            <mat-label>Email</mat-label>
            <input matInput placeholder="Title of the view" [formControl]="profileFormGroup.get('email')">
          </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="standard" required>
            <mat-label>Username</mat-label>
            <input matInput placeholder="Title of the view" [formControl]="profileFormGroup.get('username')">
          </mat-form-field>
    </div>
</form>

</div>