

<router-outlet></router-outlet>




<!-- <div *ngFor="let notification of notifications">
  {{notification| json}}
</div> -->

