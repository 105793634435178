<mat-card class="logbook-module">
  <mat-card-header>
    <mat-card-title #cardHeader style="font-size: 18px;">{{ logbook?.name }}</mat-card-title>
    <mat-card-subtitle>{{ logbook?.ownerGroup }}</mat-card-subtitle>
    <actions-menu class="mat-fab-top-right" [logbook]="logbook" (logbookEdit)="editLogbook()"
      (logbookDelete)="deleteLogbook()"></actions-menu>
  </mat-card-header>
  <div class="image-container">
    <img (click)="selection($event)" [src]="imageToShow" alt="" [routerLink]="['/logbooks', logbook?.id, 'dashboard']"
      *ngIf="!isImageLoading">
  </div>
  <mat-card-content>
    <p>
      {{ logbook?.description }}
    </p>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions class="mat-card-actions">
    <button mat-button (click)="selection()" [routerLink]="['/logbooks', logbook?.id, 'dashboard']">
      Open
    </button>
  </mat-card-actions>
</mat-card>
