<div class="header">
    <span class="headerTitle">
      Edit view
    </span>
  </div>
  <div class="saveTab">
    <form [formGroup]="editFormGroup" style="width: 80%;">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>View</mat-label>
          <mat-select [formControl]="editFormGroup.get('views')" (selectionChange)="selectView($event)">
            <ng-container *ngFor="let view of views">
              <mat-option [(value)]="view.id">{{ view.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div>
        <mat-form-field appearance="standard" required>
            <mat-label>Title</mat-label>
            <input matInput placeholder="Title of the view" [formControl]="editFormGroup.get('name')">
            <mat-error *ngIf="editFormGroup.get('name').hasError('required')">Please specify a name.</mat-error>
            <mat-error *ngIf="editFormGroup.get('name').hasError('forbiddenName')">_personal is a protected suffix.
            </mat-error>
          </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Location</mat-label>
          <mat-select [formControl]="editFormGroup.get('location')" (selectionChange)="selectLocation($event)">
            <ng-container *ngFor="let location of availLocations">
              <mat-option [(value)]="location.id">{{ location?.location }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="editFormGroup.get('location').hasError('required')">Please select a location.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard" required>
          <mat-label>ownerGroup</mat-label>
          <input matInput placeholder="ownerGroup" [matAutocomplete]="autoOwnerGroup" [formControl]="editFormGroup.get('ownerGroup')">
          <mat-error *ngIf="editFormGroup.get('ownerGroup').hasError('required')">Please specify the owner
            group.
          </mat-error>
          <mat-error *ngIf="editFormGroup.get('ownerGroup').hasError('forbiddenGroup')">You are not a member of this group.
          </mat-error>
          <mat-autocomplete #autoOwnerGroup="matAutocomplete">
            <mat-option *ngFor="let group of filteredAccessGroups | async" [value]="group">
              {{group}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-chip-list #accessGroupsChipList aria-label="AccessGroups selection" >
            <mat-chip *ngFor="let group of accessGroupsSelected" [selectable]="selectable"
              [removable]="removable" (removed)="removeAccessGroup(group)">
              {{group}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="accessGroups" #accessGroupsInput [formControl]="editFormGroup.get('accessGroupsCtrl')"
              [matAutocomplete]="autoAccessGroups" [matChipInputFor]="accessGroupsChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addAccessGroup($event)" [disabled]="editFormGroup.get('accessGroupsCtrl').disabled">
              <mat-error *ngIf="editFormGroup.get('accessGroupsCtrl').hasError('forbiddenGroup')">You are not a member of this group.
            </mat-error>
          </mat-chip-list>
          <mat-autocomplete #autoAccessGroups="matAutocomplete" (optionSelected)="selectedAccessGroup($event)">
            <mat-option *ngFor="let group of filteredAccessGroups | async" [value]="group">
              {{group}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div>
          <mat-slide-toggle [formControl]="editFormGroup.get('applyCurrentView')">
            Apply current view
          </mat-slide-toggle>
        </div>
    </form>
  </div>
  <div class="saveButton">
      <button mat-button (click)="saveLogbook()">Save changes</button>
      <div *ngIf="showSaveMessage" class="saveMessage">
          <div>
            {{ saveMessage }}
          </div>
      </div>
    </div>
