<section class="scrollable-container mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource" matSort cdkDropList cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)" (matSortChange)="onSortChange()">
    <ng-container matColumnDef="name">
      <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>Title </th>
      <td mat-cell *matCellDef="let row"> 
        <div class="scrollable-text"> 
          {{row.name}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>Description </th>
      <td mat-cell *matCellDef="let row"> 
        <div class="scrollable-text"> 
          {{row.description}} 
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="ownerGroup">
      <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>ownerGroup </th>
      <td mat-cell *matCellDef="let row"> 
        <div class="scrollable-text"> 
          {{row.ownerGroup}} 
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header>Date </th>
      <td mat-cell *matCellDef="let row"> 
        <div class="scrollable-text"> 
          {{row.createdAt | date}} 
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="thumbnail">
      <th mat-header-cell cdkDrag *matHeaderCellDef>Thumbnail </th>
      <td mat-cell *matCellDef="let row">
        <div class="scrollable-text"> 
          <img [src]="getImage(row.thumbnail)"/>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-fab-top-right"></th>
      <td mat-cell *matCellDef="let row" class="mat-fab-top-right">
        <button mat-icon-button aria-label="Actions" [matMenuTriggerFor]="menu"
          [disabled]="isActionAllowed.tooltips.edit" matTooltip="{{ isActionAllowed.tooltips.edit }}">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <span [matTooltip]="isActionAllowed.tooltips.update">
            <button mat-menu-item (click)="editLogbook(row)" [disabled]="isActionAllowed.tooltips.update">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
          </span>
          <span [matTooltip]="isActionAllowed.tooltips.delete">
            <button mat-menu-item (click)="deleteLogbook(row.id)" [disabled]="isActionAllowed.tooltips.delete">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </span>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="logbooks" *matRowDef="let row; columns: displayedColumns;" (dblclick)="openLogbook(row.id)"></tr>
  </table>

</section>

<mat-paginator [length]="totalItems" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" (page)="onPageChange()" 
  showFirstLastButtons></mat-paginator>
