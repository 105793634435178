
      <!-- <mat-chip-list #chipList aria-label="Tag selection">
        <mat-chip *ngFor="let tagItem of tag" [selectable]="selectable" [removable]="removable"
          (removed)="remove(tagItem)">
          {{ tagItem.name }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Add tags" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addTag($event)">
      </mat-chip-list> -->
      <ng-container class='tagContainer'>
        <button class='renewButton' (click)='syncWithLastEntry()'>
          <mat-icon class='renewButtonIcon' matTooltip="Copy tags from last entry">autorenew</mat-icon>
        </button>
        <mat-form-field [floatLabel]="'never'">
          <mat-chip-list #chipList aria-label="Tag selection">
            <mat-chip *ngFor="let tagItem of tag" [selectable]="selectable" [removable]="removable"
              (removed)="remove(tagItem)" class='tag'>
              {{tagItem.name}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input matInput placeholder="Add tags" #tagInput [formControl]="tagsCtrl"
              [matAutocomplete]="auto" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTags($event)">
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag" class='tagOption'>
              {{tag.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
      


