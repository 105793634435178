<app-toolbar [showMenuIcon]='mobile' (openMenu)="openMenu()" (resized)="onResized()"></app-toolbar>
<mat-sidenav-container>

    <mat-sidenav #sidenav [(mode)]="sidenavOver" [(opened)]="sidenavOpened" class="left-sidenav">
      <div class="divButtons">
        <button mat-fab color="primary" [routerLink]="['/overview']" class="divButtons-button" (click)="deactivateLogbook()">
          <mat-icon matTooltip="Logbooks">arrow_back</mat-icon>
        </button>
      </div>
      <div class="divButtons">
        <button mat-fab color="primary" [routerLink]="['/logbooks', logbookId, 'dashboard']" class="divButtons-button">
          <mat-icon matTooltip="Dashboard">dashboard</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
      <app-navigation-button [widgetConfigs]="widgetConfigs" [container]="logbookContainer" [logbookId]="logbookId" [type]="'logbook'"></app-navigation-button>
      <app-navigation-button [widgetConfigs]="widgetConfigs" [container]="tasksContainer" [logbookId]="logbookId" [type]="'task'" [badgeNumber]="numTasks"></app-navigation-button>
      <app-navigation-button [widgetConfigs]="widgetConfigs" [container]="chatContainer" [logbookId]="logbookId" [type]="'chat'"></app-navigation-button>
      <app-navigation-button [widgetConfigs]="widgetConfigs" [container]="plotContainer" [logbookId]="logbookId" [type]="'plot'"></app-navigation-button>
      <app-navigation-button [widgetConfigs]="widgetConfigs" [container]="snippetViewerContainer" [logbookId]="logbookId" [type]="'snippetViewer'"></app-navigation-button>

    </mat-sidenav>
    <mat-sidenav-content class="main-content" [ngStyle]="{'padding-left':dashboardView === true ? '0px' : 'calc(0.5vh)' }">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>