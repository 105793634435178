<ng-container>
<div class="graph">
         <canvas baseChart
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [type]="lineChartType"
                [options]="lineChartOptions">
        </canvas>
</div>

</ng-container>






