<mat-card #snippetContainer [ngClass]="styleClass" (click)="selectSnippet($event)" [@highlightSnippet]="highlightState">
  <!-- [ngStyle]="{'border':isSelected ? '2px solid #26677a' : '2px solid #ffffff' }"> -->
  <mat-icon class="mat-fab-top-right-star" *ngIf="isHighlighted">star</mat-icon>
  <!-- <mat-icon class="mat-fab-top-right-user" *ngIf="!enableEdit" matTooltip="Another user is currently editing this snippet.">account_circle</mat-icon> -->
  <svg class="mat-fab-top-right-user" [data-jdenticon-value]="avatarHash"
    *ngIf="snippetIsAccessedByAnotherUser && !snippet.isMessage"
    matTooltip="Another user is currently editing this snippet."></svg>
  <!-- <svg class="mat-fab-top-right-user" [data-jdenticon-value]="avatarHash" *ngIf="snippet.isMessage" [matTooltip]="snippet.updatedBy"></svg> -->
  <ng-container *ngIf="showEditButtonsMenu">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
      class="mat-fab-top-right">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="showInfo()" *ngIf="showInfoButton">
        <mat-icon>info</mat-icon>
        <span>Info</span>
      </button>
      <button mat-menu-item (click)="editSnippet()" [disabled]="!enableEdit.update" *ngIf="showEditButtons" matTooltip="{{ isActionAllowed.tooltips.update }}">
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>
      <button mat-menu-item (click)="setDashboardName()" [disabled]="!enableEdit.update" matTooltip="{{ isActionAllowed.tooltips.update }}">
        <mat-icon>assistant</mat-icon>
        <span>Dashboard name</span>
      </button>
      <button mat-menu-item (click)="addComment()" *ngIf="enableComments">
        <mat-icon>note_add</mat-icon>
        <span>Add comment</span>
      </button>
      <button mat-menu-item (click)="addCitation()">
        <mat-icon>reply</mat-icon>
        <span>Reply</span>
      </button>
      <!-- <button mat-menu-item disabled> -->
      <button mat-menu-item (click)="deleteSnippet()" [disabled]="!enableEdit.delete" matTooltip="{{ isActionAllowed.tooltips.delete }}"
        *ngIf="showEditButtons">
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </ng-container>


  <!-- <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" class="mat-fab-top-right">
            <mat-icon>menu</mat-icon>
        </button> -->
  <!-- <span>
          <svg class='message-avatar' [data-jdenticon-value]="avatarHash" *ngIf="snippet.isMessage" [matTooltip]="snippet.updatedBy"></svg>
        </span>
        <span>
          {{ snippet.createdAt }}
        </span> -->
  <!-- <mat-card-title class='message-timestamp' *ngIf="snippet.isMessage">{{ snippet.createdAt }}</mat-card-title> -->
  <!-- <mat-card-subtitle>#{{ index }} / {{ snippet.owner }} / {{ snippet.ownerGroup }}</mat-card-subtitle> -->

  <span *ngIf="snippet.isMessage" class="card-header">
    <span>
      <svg class='message-avatar' [data-jdenticon-value]="avatarHash" [matTooltip]="snippet.updatedBy"></svg>
    </span>

    <span class="message-timestamp">
      {{ snippet.createdAt | date:'medium' }}
    </span>
  </span>

  <mat-card-content class="mat-card-content">
    <div *ngFor="let snippet of snippet?.subsnippets; let i = index">
      <ng-container *ngIf="snippet?.linkType=='quote'">
        <snippet [snippet]="snippet" [updatedAt]="snippet?.updatedAt" [index]=i [hideMetadata]='hideMetadata'></snippet>
      </ng-container>
    </div>
    <!-- <div *ngIf="snippet.quote">
      <snippet [snippet]="snippet.quote" [updatedAt]="snippet.quote?.updatedAt" [index]="index" [linkType]="'QUOTE'" [showEditButtonsMenu]="false"></snippet>
    </div> -->
    <!-- {{index}} -->
    <span class="snippet-header" *ngIf="config.view.showSnippetHeader">
      {{indexOrdered}} / {{ snippet.createdAt | date:'medium' }} / {{snippet.createdBy}}
    </span>
    <snippet-content #snippetContent [snippet]='snippet' (htmlContent)="updateHtmlContent($event)"
      (isLoading)="setElementLoading($event)">
    </snippet-content>
    <div class="snippet-tags-container" *ngIf="!_hideMetadata">
      <span class="snippet-tag" *ngFor="let tag of snippet?.tags">
        {{ tag }}
      </span>
    </div>

  </mat-card-content>

</mat-card>

<div *ngFor="let snippet of snippet?.subsnippets; let i = index">
  <ng-container *ngIf="snippet.linkType!='quote' && snippet.snippetType === 'paragraph'">
    <snippet [snippet]="snippet" [updatedAt]="snippet.updatedAt" [index]=i [config]="config"
      [hideMetadata]='hideMetadata' [subsnippets]="snippet.subsnippets"></snippet>
  </ng-container>
</div>