<app-toolbar (overviewSearch)="setSearch($event)"></app-toolbar>
<div class="overview-container">
  <div>
    <h1>Logbooks</h1>
    <mat-button-toggle-group [(ngModel)]="matCardType">
      <mat-button-toggle value="logbook-module"><mat-icon>view_module</mat-icon></mat-button-toggle>
      <mat-button-toggle value="logbook-headline"><mat-icon>view_headline</mat-icon></mat-button-toggle>
    </mat-button-toggle-group>
    <button (click)='addCollectionLogbook("logbook")'>Add logbook</button><br>
    <mat-spinner *ngIf="!overviewComponent?.isLoaded" [@spinner] class="spinner"></mat-spinner>
    <overview-table *ngIf="matCardType === 'logbook-headline'" [config]="config" (logbookEdit)="editLogbook($event)"
      (logbookSelection)="logbookSelected($event)"></overview-table>
    <overview-scroll *ngIf="matCardType === 'logbook-module'" [config]="config" (logbookEdit)="editLogbook($event)"
      (logbookSelection)="logbookSelected($event)"></overview-scroll>
  </div>
</div>
