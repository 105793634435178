<div class="header">
  <span class="headerTitle">
    New logbook
  </span>

</div>
<div class="logbook-container">
  <gridster [options]="optionsGrid" class="gridster-container">
    <gridster-item [item]="{cols: 1, rows: 1, y: 0, x: 0}" class="gridster-item">
      <form [formGroup]="optionsFormGroup">
        <div>
          <mat-form-field appearance="standard" required>
            <mat-label>Title</mat-label>
            <input matInput placeholder="Title" [formControl]="optionsFormGroup.get('title')" matTooltip="{{ isActionAllowed.tooltips.expired }}">
            <mat-error *ngIf="optionsFormGroup.get('title').hasError('required')">Please specify the title.</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="standard">
            <mat-label>Location (Beamline or Instrument)</mat-label>
            <mat-select [formControl]="optionsFormGroup.get('location')" (selectionChange)="selectLocation($event)" matTooltip="{{ isActionAllowed.tooltips.expired }}">
              <ng-container *ngFor="let location of availLocations">
                <mat-option [(value)]="location.id">{{ location?.location }}</mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="optionsFormGroup.get('location').hasError('required')">Please select a location.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <!-- <mat-form-field appearance="standard" required>
                        <mat-label>ownerGroup</mat-label>
                        <input matInput placeholder="ownerGroup" [formControl]="optionsFormGroup.get('ownerGroup')">
                        <mat-error *ngIf="optionsFormGroup.get('ownerGroup').hasError('required')">Please specify the owner group.</mat-error>
                    </mat-form-field> -->
          <mat-form-field appearance="standard" required>
            <mat-label>ownerGroup</mat-label>
            <input matInput placeholder="ownerGroup" [matAutocomplete]="autoOwnerGroup"
              [formControl]="optionsFormGroup.get('ownerGroup')" matTooltip="{{ isActionAllowed.tooltips.ownerGroup }}">
            <mat-error *ngIf="optionsFormGroup.get('ownerGroup').hasError('required')">Please specify the owner
              group.
            </mat-error>
            <mat-error *ngIf="optionsFormGroup.get('ownerGroup').hasError('forbiddenGroup')">You are not a member of
              this group.
            </mat-error>
            <mat-error *ngIf="optionsFormGroup.get('ownerGroup').hasError('anyAuthGroup')">Please set any-authenticated-user later when editing 
              the logbook.
            </mat-error>
            <mat-autocomplete #autoOwnerGroup="matAutocomplete">
              <mat-option *ngFor="let group of filteredOwnerGroups | async" [value]="group">
                {{group}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-chip-list #chipList aria-label="AccessGroups selection" formControlName="accessGroups">
              <mat-chip *ngFor="let group of accessGroups.value" [selectable]="selectable" [removable]="removable"
                (removed)="removeAccessGroup(group)">
                {{group}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="accessGroups" #accessGroupsInput [formControl]="accessGroupsCtrl"
                [matAutocomplete]="auto" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addAccessGroup($event)">
            </mat-chip-list>
            <mat-error *ngIf="accessGroups.hasError('anyAuthGroup')">Please set any-authenticated-user later when editing 
              the logbook.
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedAccessGroup($event)">
              <mat-option *ngFor="let group of filteredAccessGroups | async" [value]="group">
                {{group}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div>
          <mat-slide-toggle [formControl]="optionsFormGroup.get('isPrivate')" matTooltip="{{ isActionAllowed.tooltips.expired }}">private</mat-slide-toggle>
        </div>
        <div>
          <mat-form-field appearance="standard" required>
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Description" [formControl]="optionsFormGroup.get('description')" matTooltip="{{ isActionAllowed.tooltips.expired }}"></textarea>
          </mat-form-field>
        </div>
      </form>
    </gridster-item>
    <gridster-item [item]="{cols: 1, rows: 1, y: 0, x: 1}" class="gridster-item">
      <img mat-card-image [src]="imageToShow" alt="" *ngIf="imageLoaded" />
      <div>
        <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput>
        <button color="accent" aria-label="Thumbnail" class="mat-fab-bottom-right" (click)="fileInput.click()" disabled="{{ isActionAllowed.tooltips.expired }}" matTooltip="{{ isActionAllowed.tooltips.expired }}">
          {{ thumbnailText }}
        </button>
        <button color="accent" aria-label="RemoveThumbnail" class="mat-fab-bottom-right" (click)="removeThumbnail()"
          disabled="{{ isActionAllowed.tooltips.expired }}" matTooltip="{{ isActionAllowed.tooltips.expired }}"
          *ngIf="imageLoaded">
          Delete
        </button>

      </div>
    </gridster-item>
  </gridster>
</div>


<!-- <div class="logbook-container">

    <form [formGroup]="options">
        <mat-divider></mat-divider>
        <div class="image-container">
            <span class="title-block">
                <div>
                    <mat-form-field appearance="standard" required>
                        <mat-label>Title</mat-label>
                        <input matInput placeholder="Title" [formControl]="title">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="standard">
                        <mat-label>Location</mat-label>
                        <mat-select (selectionChange)="selectLocation($event)">
                            <ng-container *ngFor="let location of availLocations">
                                <mat-option [(value)]="location.id">{{ location?.location }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </span>

            <span *ngIf="imageLoaded" class="image-block">
                <img mat-card-image [src]="imageToShow" alt="" />
                <div >
                    <button mat-button (click)="close()">Change thumbnail</button>

                </div>

            </span>

        </div>
        <div class="description-container">
            <mat-form-field appearance="standard" required>
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Description" [formControl]="description"></textarea>
            </mat-form-field>
        </div>





        <mat-divider></mat-divider>
    </form>
</div> -->
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Cancel</button>
  <button mat-button (click)="addLogbook($event)" [disabled]="optionsFormGroup.invalid">OK</button>
</mat-dialog-actions>
