<div class="divButtons">
    <ng-container *ngIf="container.length>1">
        <button mat-fab [matMenuTriggerFor]="menuItem">
            <mat-icon [matBadge]="badgeNumber" [matBadgeHidden]="badgeNumber<1" matBadgeColor="warn" [(matTooltip)]="iconTip">{{iconName}}</mat-icon>
        </button>
      <mat-menu #menuItem="matMenu" >
          <ng-container *ngFor="let entry of container">
            <button mat-menu-item (click)="navigateToItem(entry)">
                <span>{{ widgetConfigs[entry].config.title }}</span>
              </button>
          </ng-container>
      </mat-menu>

    </ng-container>
    <ng-container *ngIf="container.length==1">
      <button mat-fab (click)="navigateToItem(container[0])" class="divButtons-button">
        <mat-icon [matBadge]="badgeNumber" [matBadgeHidden]="badgeNumber<1" matBadgeColor="warn" [(matTooltip)]="iconTip">{{ iconName }}</mat-icon>
      </button>
    </ng-container>
</div>