<div class="settings-container">

    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sidenav [(mode)]="sidenavOver" [(opened)]="sidenavOpened" class="left-sidenav">
            <div class='logo'>
                <img src="../../assets/logo.png" alt="SciLog" width="50" height="50" />
            </div>
            <div class="divButtons">
                <button mat-fab [routerLink]="[{outlets: {'settings': ['profileSettings']}}]">
                    <mat-icon matTooltip="Profile">account_circle</mat-icon>
                </button>
            </div>
            <div class="divButtons">
                <button mat-fab [routerLink]="[{outlets: {'settings': ['viewSettings']}}]">
                    <mat-icon matTooltip="Views">preview</mat-icon>
                </button>
            </div>
            <!-- <div class="divButtons">
                <button mat-icon-button>
                    <mat-icon matTooltip="Collection">apps</mat-icon>
                    <span style="padding-left: 8px;">Widgets</span>
                </button>
            </div> -->

        </mat-sidenav>
        <mat-sidenav-content class="main-content">
                <button mat-mini-fab (click)="close()" class="dialog-footer">
                    <mat-icon matTooltip="Close">close</mat-icon>
                </button>
            <router-outlet name='settings'></router-outlet>
        </mat-sidenav-content>

    </mat-sidenav-container>


</div>